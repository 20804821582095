<script setup lang="ts">
function enter(element: any) {
  const width = getComputedStyle(element).width
  element.style.width = width
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const height = getComputedStyle(element).height

  element.style.width = null
  element.style.position = null
  element.style.visibility = null
  element.style.height = 0

  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height
  })
}

function afterEnter(element: any) {
  element.style.height = 'auto'
}

function leave(element: any) {
  const height = getComputedStyle(element).height

  element.style.height = height

  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(element).height

  requestAnimationFrame(() => {
    element.style.height = 0
  })
}
</script>

<template>
  <transition
    name="height"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.height-enter-active,
.height-leave-active {
  transition: height 500ms ease-in-out;
  overflow: hidden;
}

.height-enter,
.height-leave-to {
  height: 0;
}
</style>
